.calenderContainer {
    position: relative;
    .css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-right___h_uSC-sass {
     
      width: 900px;
    }
    .selectDeliveryDetailOption{
      right: 0;
      display: flex;
      justify-content: flex-end;
      margin-right: 85px;
      position: absolute;
      top: 7px;
      z-index: 99999;
      select{
        width:200px;
      }
    }
    @media (max-width: 390px) {
      .selectDeliveryDetailOption {
          position: inherit;
          margin-right: 15px;
      }
    }
    .OrderDetailsBox{
      .searchField{
        display:flex;
        justify-content: flex-end;
        margin-right: 20px;
      }
      .refLink{
        color:#1675e0;
      }
    }
    .deliveryCalenderChart {
      margin-top: 3.2rem;
      + .deliveryCalenderChart {
        margin-top: 0.75rem;
      }
    
        .p-3{
          .progressBar{
            display: flex;
            flex-direction: column;
            gap: 1rem;
  
          }
        }
    
    }
    .CalenderHeader{
      
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      h6{
        margin: 0;
      }
    }
    .rs-calendar-bordered {
      .rs-calendar-body {
        .rs-calendar-table {
          overflow: hidden;
          .rs-calendar-table-row {
            &.rs-calendar-table-header-row {
              background: #f5f6fa;
              .rs-calendar-table-header-cell {
                font-weight: 700;
                .rs-calendar-table-header-cell-content {
                  color: #242424;
                  font-size: 14px;
                  display: block;
                  padding: 0.25rem 0;
                }
              }
            }
            .rs-calendar-table-cell {
              border-right: 1px solid var(--rs-border-secondary);
              &:last-child {
                border-right: none;
              }
              .rs-calendar-table-cell-content {
                width: 100%;
                height: 100px;
                overflow: hidden;
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                justify-content: space-between;
                padding: 10px;
                .rs-calendar-table-cell-day {
                  font-size: 16px;
                  font-weight: bold;
                  width: 36px;
                  height: 36px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin: inherit;
                }
                .calenderDates {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: auto;
                  .progressCircle {
                    overflow: hidden;
                    border-radius: 100%;
                    .rs-progress-circle-info {
                      font-size: 10px;
                      color: black;
                      background: #f5f5f5;
                      z-index: 1;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                    svg {
                      position: relative;
                      z-index: 2;
                    }
                  }
                }
              }
  
              &.rs-calendar-table-cell-is-today {
                .rs-calendar-table-cell-content {
                  background-color: #f2faff;
                  box-shadow: none;
                  // .rs-calendar-table-cell-day {
                  //   width: 36px;
                  //   height: 36px;
                  //   display: flex;
                  //   align-items: center;
                  //   justify-content: center;
                  // }
                }
              }
            }
          }
        }
      }
    }
  }
  .dashboardHeader{
    display:flex;
    justify-content: flex-end;
    gap:1rem;
   
  
}
@media (max-width: 470px) {
  .dashboardHeader {
     flex-direction: column;
  }
} 
  
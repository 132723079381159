//
// accordion.scss
//

body[data-layout-mode="dark"] {

    // accordion

    .accordion-button{
      border-color: $gray-dark-300;
      color: $gray-dark-600;
    
    }

    .accordion-item{
        border-color: $gray-dark-300;
    }
    
    .accordion-collapse{
        border-color: $gray-dark-300;
    }
}
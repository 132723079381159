$color1: #e9e9e9;
$color2: #50c0a8;
$white: #ffffff;
$br4: 4px;
.AddPromotions {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .contain {
    border: 1px solid $color1;
    border-radius: $br4;
    margin: 0.5rem 0;
    background: #f5f6fa;
    margin-bottom: 20px;

    .success {
      color: #50c0a8;
    }
    .promotionOptions {
      display: flex;
      gap: 0.5rem;
      .promotionButtons {
        background: #ffffff;
        border: 1px solid #e5e9eb;
        border-radius: 8px;
        opacity: 1;
        width: 153px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.3rem;
        cursor: pointer;

        i {
          font-size: 20px;
        }
      }
      .active {
        background: #506deb;
        border: 1px solid #2e4dd4;
        color: white;
        i {
          color: white;
        }
      }
    }
  }

  .promoBtn {
    position: absolute;
    top: 24px;
    right: -57px;
    &.Close {
      background-color: transparent;
      border: none;
    }
  }
}
.wallet {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-left: 5px;
  .walletAmount {
    font-size: 28px;
    font-weight: bold;
  }
}
.walletInput {
  display: flex;
}
.giftSucessIcon {
  color: transparent;
  margin: 0em 0.5em 0em 0.1em;
}
.removeCoupon {
  float: right;
  color: red;
  cursor: pointer;
}

//
// custom-styles.scss
//

.status {
  padding: 4px 5px !important;
  margin-top: 0px !important;
  &.ProgressStep {
    background-color: #f5f6fa;
    box-shadow: none;
    border-color: #f5f6fa;
  }
}

label {
  font-size: 13px;
  margin-bottom: 0.2rem;
}
//------- BARI -------//@at-root
// .btn-info {
//   color: #fff;
//   background-color: #333333;
//   border-color: #333333;
//   box-shadow: none;
//   &:hover {
//     background-color: #545454;
//     border-color: #545454;
//     box-shadow: none;
//   }
// }
.badge {
  padding: 0.5em 0.6em;
  line-height: 1;
  border-radius: 0.2rem;
}

// .bg-progress-success1 {
//   background-color: #f0fcf8 !important;
//   color: black;
//   border: 1px solid #50c0a8 !important;
// }
.notLs {
  background: #fff7d3;
}
.netTotal {
  color: green;
}
.bg-success-rating {
  background-color: #0e7c35 !important;
}
.bg-primary-rating {
  background-color: #206699 !important;
}
.bg-warning-rating {
  background-color: #e2b021 !important;
}
.bg-danger-rating {
  background-color: #ca1f1f !important;
}
.noTxtWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: unset;
  width: 102px;
}
.mapSize {
  width: 100%;
}
.mapSize img {
  width: 100%;
}
.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-right___h_uSC-sass {
  width: 543px;
}
// .css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-right___h_uSC-sass {
//   width: 90% !important;
// }
.walletDrawerContain {
  .css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-right___h_uSC-sass {
    width: 98%;
  }
}
.img-thumbnail1 {
  border: 1px solid #e9e9ef !important;
  border-radius: 4px;
  cursor: pointer;
}
.highlightInfo {
  float: left;
  font-size: 11px;
  color: #1f8c51;
  font-weight: 600;
  width: 100%;
  display: block;
  overflow: hidden;
}
.highlight {
  font-size: 7px;
  font-weight: 600;
}
.highlightPara {
  display: block;
  overflow: hidden;
  word-break: break-word;
}
.ReturnInfo1:hover .highlightInfo {
  overflow: auto;
}
.ReturnInfo1:hover .highlightInfo5 {
  overflow: auto;
}
.ReturnInfo1:hover .highlightPara {
  overflow: auto;
}

.highlightInfo1 {
  display: block;
  font-size: 11px;
  color: #1f8c51;
  width: 100%;
}
.highlightInfo5 {
  float: left;
  font-size: 11px;
  padding: 0px;
  width: 100%;
  display: block;
  overflow: hidden;
  color: #ca1f1f;
  font-style: italic;
}
.version {
  margin-top: 5px;
}
.highlightInfo3 {
  font-size: 11px;
  color: #1f8c51;
  font-weight: 600;
  width: 100%;
}
.highlightInfo2 {
  display: block;
  font-size: 11px;
  width: 100%;
}

.expressBx {
  background: #e3660e;
  display: inherit;
  color: #fff;
  font-weight: 900;
  font-size: 11px;
  padding: 1px 4px;
  border-radius: 3px;
}
.shipmentInfoBx {
  display: inherit;
  font-size: 11px;
}
.shipmentDaysInfo {
  font-size: 11px;
  .badgeModified {
    border-radius: 50%;
  }
}
.slotBx {
  padding-left: 3px;
  font-size: 11px;
}
.slotDetailBx {
  margin-bottom: 10px; // Adds margin space at the bottom of the element
  padding: 15px !important ;
}
.userInfoIcon {
  width: 15px;
  border: #cb8686 solid 1px;
  padding: 2px;
  border-radius: 3px;
  margin-left: 5px;
}

.ReturnInfo1 {
  max-width: 215px;
}
.proWidth {
  height: 4px;
  width: 75%;
}
.reviewSpan {
  box-shadow: 0px 1px 2px #28282833;
  padding: 0px 2px;
}
.deliveryreviewSpan {
  box-shadow: 0px 1px 2px #28282833;
  padding: 6px 7px;
  font-size: 11px;
  color: rgb(31, 29, 29) !important;
}
.failedBtn {
  background-color: red;
  color: white;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
.successBtn {
  background-color: green;
  color: white;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
}
#outer {
  width: 100%;
}
.inner {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
}
.paymentDetailSec {
  label {
    display: block;
  }
}
.dh-custom-tab {
  margin: 0;
}
.dh-TabRow {
  padding: 8px 8px;
  background: #e9e9ef;
  margin: 0 0px;
  // margin-bottom: 5px;
  // border-radius: 4px;
  // border: #b6c3d0 solid 1px;
  // background: #e8f1fa;
}
.refno {
  cursor: pointer;
}
.mr-2 {
  margin-right: 10px;
}
.noWrapText {
  max-width: 202px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.customerComment:hover {
  overflow: auto;
  overflow-y: hidden;
}
.noWrapText2 {
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.noWrapText1 {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.orderViewButton {
  float: right;
  margin-top: 36px;
}
.searchIcon1 {
  margin-top: -11px;
  position: absolute;
  top: 50%;
  right: 10px;
}
.region {
  width: 10%;
}

.rs-picker-menu {
  z-index: 999999 !important;
}
.deviceDataBlock {
  float: left;
  width: 100%;
  border: #e3dab4 solid 1px;
  padding: 5px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  background: #f9f7ed;
  span {
    float: left;
  }
}
.refColor {
  color: black;
}
.formAlignment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.headPadding {
  padding: 16px 0px 5px 16px;
}
.rowCenter {
  justify-content: center;
}
.marginRiight {
  margin-right: 8px;
}
.importError {
  color: rgb(255, 0, 0);
}
.imageDiv {
  margin-bottom: 10px;
}
.imageContent {
  margin-bottom: 17px;
}
.itemBx {
  width: 360px;
  white-space: break-spaces;
  text-overflow: ellipsis;
}

.p-Wrap {
  margin-top: 0;
  max-width: 300px;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.shippingMethodStyle {
  display: inline-block;
  background-color: #d4e4f5 !important;
  color: #000000;
  padding: 5px;
  border: 1px solid #88b6d8;
  border-radius: 3px;
  font-size: 13px;
}

#text,
#icon {
  line-height: 20px;
}
#icon {
  vertical-align: middle;
}
.paraFont {
  font-size: 11px;
}
.statusDetail {
  height: 100%;
}
.catbuttonDropdown {
  min-width: 100% !important;
}
.btn-success {
  background-color: #249a6a !important;
  border-color: #249a6a !important;
}
.icons {
  display: flex;
  flex-direction: row;
}
.icon1 {
  border: 1px solid #33333375;
  opacity: 1;
  width: 40px;
  padding: 3px 39px 3px 10px !important;
  border-radius: 5px;
  margin-left: 10px;

  img {
    width: 20px;
    height: 20px;
  }
}
.previewButton {
  width: 100%;
}
.loader-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.834) no-repeat;
  z-index: 1;
}
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgb(255, 255, 255);
  z-index: 9999;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
}
.productExport {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 390px) {
  .DashbordFliter1 {
    grid-gap: 0.2rem !important;
    gap: 0.2rem !important;
  }
}
.search-magnifier-icon {
  cursor: pointer;
}
.card_header {
  background: #f2f2f2 0% 0% no-repeat padding-box;
}
.deliverySchedule {
  border-bottom: 1px solid #80808052;
  padding-bottom: 10px;
}
.checkoutSwiper {
  padding-bottom: 12px;
  margin-bottom: 8px;
}

.ChooseDeliveryDateSlot {
  overflow: auto;
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding: 0px !important;
  li {
    text-align: center;
    list-style-type: none;
    padding: 0.5rem;
    width: calc(16.5% - 0.5rem);
    background: #f6f6f8;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    grid-gap: 0.1rem;
    grid-gap: 0.1rem;
    gap: 0.1rem;
    flex-direction: column;
    cursor: pointer;
    line-height: 15px;
    &:first-child {
      background: #5491f5 !important;
      border: 1px solid #5491f5 !important;
      color: white;
      opacity: 0.4;
      cursor: not-allowed;
    }

    &.Active {
      background: #5491f5 !important;
      border: 1px solid #5491f5 !important;
      color: white;
      cursor: pointer;
    }
  }
}

.PickConvenientTimeSlot {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  padding: 0px;
  li {
    padding: 0.35rem 0rem;
    background: #f6f6f8;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    width: calc(33% - 0.5rem);
    text-align: center;
    list-style-type: none;
    &.Active {
      background: #5491f5 !important;
      border: 1px solid #5491f5 !important;
      color: white;
      cursor: pointer;
    }
  }
}

.cancelBtn {
  margin-right: 15px;
}
.textAlignLeft {
  text-align: left;
  display: flex;
}
.scheduleImage {
  display: flex;
  gap: 1rem;
  span {
    display: flex;
    align-items: center;
    color: #2c7651;
  }
}
.customerComment {
  border: 1px solid #00000033;
  padding: 3px 0px 3px 8px;
  border-radius: 3px;
  display: flex;
  gap: 0.3rem;
  line-height: 17px;
}
.commentSec {
  width: 100px;
}
.colWidth {
  width: 200px;
}

.item-container {
  background-color: #ffffff;
  border: 1px solid #00000024;
  margin-bottom: 15px;
  border-radius: 5px;
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  span {
    padding-left: 10px;
  }
}
.dragImage {
  background-color: #e7ebee;
  padding: 15px;
}
.dragImage2 {
  padding-right: 10px;
}
.cardStyle {
  display: flex;
  flex-direction: column;
}
.cardBackground {
  background-color: #f6f6f8;
  border: 1px solid #e3ebf9;
  border-radius: 6px;
  opacity: 1;
}
.catMain {
  display: flex;
  gap: 1rem;
}

.catOption {
  min-width: 196px;
}

@media (max-width: 808px) {
  .catOption {
    display: none;
  }
}
.trackButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
// Modal Popup Style Open ----------------------------------
body {
  &.ReactModal__Body--open {
    overflow: hidden;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  z-index: 999;
  background-color: rgb(0 0 0 / 20%) !important;
  backdrop-filter: blur(4px);
  transition: all 300ms ease-in-out;

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content {
    opacity: 0;
    transition: all 300ms ease-in-out;

    &.ReactModal__Content--after-open {
      opacity: 1;
    }

    &.ReactModal__Content--before-close {
      opacity: 0;
    }
  }

  .open {
    width: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-right: -50%;
    border: 1px solid white;
    background: white;
    overflow: hidden;
    border-radius: 5px;
    outline: none;
  }

  .openCenter {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-right: -50%;
    border: 1px solid white;
    background: white;
    overflow: hidden;
    border-radius: 5px;
    outline: none;
  }

  .openRight {
    position: absolute;
    top: 0;
    bottom: 0;
    border: 1px solid var(--white);
    // border-radius: var(--border-radius-small);
    overflow: hidden;
    outline: none;
    background-color: var(--white);
    transform: translateX(100px);
    width: 100%;
    height: calc(100% - 0px);
    margin-right: 0px;
    margin-left: auto;

    &.ReactModal__Content--after-open {
      transform: translateX(0px);
    }

    &.ReactModal__Content--before-close {
      transform: translateX(100px);
    }
  }

  .openDown {
    position: absolute;
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    margin-right: 0px;
    margin-left: 0px;
    border: 1px solid var(--white);
    background: var(--white);
    overflow: hidden;
    border-radius: var(--border-radius-regular) var(--border-radius-regular) 0 0;
    outline: none;
    transform: translatey(100px);
    max-height: 85vh;
    overflow: auto;

    &.ReactModal__Content--after-open {
      transform: translatey(0px);
    }

    &.ReactModal__Content--before-close {
      transform: translatey(100px);
    }
  }

  .openLeft {
    position: absolute;
    top: 5px;
    bottom: 5px;
    right: 5px;
    border: 1px solid white;
    border-radius: 5px;
    overflow: hidden;
    outline: none;
    background-color: white;
    transform: translateX(-100px);
    height: calc(100% - 12px);

    &.ReactModal__Content--after-open {
      transform: translateX(0px);
    }

    &.ReactModal__Content--before-close {
      transform: translateX(-100px);
    }
  }
}

// html[dir*='ltr'] {
html[dir*='ltr'] {
  .ReactModal__Overlay {
    .openRight {
      right: 0px;
    }
  }
}

// html[dir*='rtl'] {
html[dir*='rtl'] {
  .ReactModal__Overlay {
    .openRight {
      left: 0px;
    }
  }
}
.viewQueries {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 313px;
    height: 40px;
    border: 1px solid #333333;
    border-radius: 4px;
    opacity: 1;
    gap: 0.5rem;
  }
}
.comentsIframe {
  iframe {
    width: 100%;
    height: 500px;
  }
}
.productNameCustom {
  width: 250px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.wallet-info {
  background: transparent
    linear-gradient(111deg, #518af3 0%, #528af2 51%, #225ece 100%) 0% 0%
    no-repeat padding-box;
}
.silver {
  background: transparent
    linear-gradient(
      113deg,
      #c7c7c7 0%,
      #d1d1d1 17%,
      #f7f7f7 50%,
      #c7c7c7 81%,
      #cecece 100%
    )
    0% 0% no-repeat padding-box;
}
.platinum {
  background: transparent
    linear-gradient(
      113deg,
      #acc1d5 0%,
      #d6e4f4 27%,
      #fafeff 62%,
      #c4d9e6 82%,
      #acc1d5 100%
    )
    0% 0% no-repeat padding-box;
}
.gold {
  background: transparent
    linear-gradient(
      113deg,
      #e0a953 0%,
      #ecc766 27%,
      #eece6d 41%,
      #f9ea8d 67%,
      #c7953b 100%
    )
    0% 0% no-repeat padding-box;
}

.roleStatus {
  .form-check {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .form-check-input {
      &:checked {
        background-color: $success;
        border-color: $success;
      }
    }
  }
}

$green: #23997f;
$light-green: #50c0a8;
$blue: #5491f5;
$light-blue: #78a8f6;
$grey: #dfdfe6;
$white: #ffffff;
$br4: 4px;
$highlightRed: #d12e27;
$highlightRedLight: #f19e9a;
$highlightRedLighter: #fef9f9;
$highlightBlueDark: #2f5181;
$highlightBlue: #5491f5;
$highlightBlueSecondary: #f5f7fe;
$highlightBlueLight: #e3ebf9;
$highlightBlueLighter: #f3f7fe;
$highlightBlueExtraLighter: #f6f6f8;
$neutralDarker: #707070;
$neutralDark: #989898;
$neutral: #e1e1e1;
$neutralSecondary: #d5d5d5;
$neutralLight: #e8e8e8;
$neutralLighter: #f1f1f1;
$secondaryDark: #03a685;
$secondary: #50c0a8;
$secondaryLight: #e1fff8;

.DeliverySlotSelection {
  .viewDeliverySlots {
    font-weight: bold;
    background-color: $green !important;
    color: $white !important;
    &:hover {
      background-color: $light-green !important;
    }
  }
  .createNewOrderPopup {
    .card-header {
      border-bottom: 1px solid var(--rs-border-primary);
    }
    .card {
      border: none;
      margin-bottom: 0px;
      label,
      p {
        font-size: 14px;
        strong {
          font-size: 16px;
          font-weight: 700;
        }
        &.title {
          margin-bottom: 15px;
        }
      }
    }
    hr {
      &.divider {
        height: 0.75rem;
        margin: 1rem -1.25rem;
        background-color: $grey;
        box-shadow: inset 0 3px 8px $grey;
        opacity: 1;
      }
    }
    .btn-primary {
      font-size: 16px;
      font-weight: 700;
    }
    .card-body {
      text-align: left;
      padding-left: 2em;
    }
    .card-header h5 {
      text-align: left;
    }
  }
}

.deliverySlotScheduled {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  .deliverySlotScheduledTitle {
    padding: 1rem;
    border-bottom: 1px solid $grey;
    position: relative;
    text-align: center;
    h3 {
      margin-bottom: 0;
    }
  }
  .lottieLoader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .tileSlots {
    overflow: auto;
    position: relative;
    font-size: 12px !important;
  }
  .deliveryShipmentsContain {
    display: flex;
    gap: 1rem;
    flex-direction: column;
    height: calc(100vh - 150px);
    overflow-x: hidden;
    overflow-y: scroll;

    .deliveryShipments {
      padding: 1rem;
      border-bottom: 1px solid $highlightBlueExtraLighter;
      &:last-child {
        border-bottom: none;
      }
      h6 {
        color: $neutralDark;
        margin-bottom: 8px;
      }
      h5 {
        margin-bottom: 5px;
      }
      p {
        margin-bottom: 5px;
        font-size: 12px;
        &.expectedDelivery {
          color: $secondaryDark;
          strong {
            display: flex;
            gap: 0.75rem;
            align-items: center;
            color: $secondaryDark;
            em {
              color: $secondaryDark;
            }
          }
        }
      }
      ul {
        &.ShipmentItems {
          display: flex;
          gap: 0.5rem;
          margin: 1rem 0;
          padding: 0;

          li {
            overflow: hidden;
            line-height: 0px;
            background-color: $neutralLighter;
            img {
              border-radius: 4px;
              mix-blend-mode: multiply;
            }
            &.ShipmentMoreItems {
              line-height: 75px;
              width: 75px;
              text-align: center;
              background: $highlightBlueExtraLighter;
              font-size: var(--font-size-large);
              border: 2px dashed $neutral;
              color: $neutralDarker;
              letter-spacing: 2px;
              font-weight: 500;
              border-radius: 4px;
            }
          }
        }
      }
      .deliveryShipmentsSchedule {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .chooseDeliveryShipmentsScheduleDate {
          .ChooseDeliveryDateTitle {
            padding: 0.5rem 0;
            display: flex;
            gap: 1rem;
            .ChooseDeliveryDateTitleInner {
              h5 {
                margin-bottom: 0px;
              }
            }
          }
          ul {
            overflow: auto;
            margin: 0px;
            padding: 0;
            &.chooseDeliveryShipmentsDateSlot {
              display: flex;
              gap: 0.5rem;
              li {
                text-align: center;
                min-width: 80px;
                list-style: none;
                p {
                  &.ChooseDeliveryDateAvailableSlot {
                    padding: 0.3rem 1rem;
                    background: $highlightBlueExtraLighter;
                    border: 1px solid $neutral;
                    font-size: 12px;
                    border-radius: 4px;
                    cursor: pointer;
                    font-weight: 500;
                    display: flex;
                    gap: 0.1rem;
                    flex-direction: column;
                    &:hover {
                      transition: 0.3s;
                      background: #5491f5;
                      border: 1px solid #5491f5;
                      color: $white;
                    }
                    &.active {
                      background: #5491f5;
                      border: 1px solid #5491f5;
                      color: $white;
                    }
                    span {
                      flex-direction: column;
                    }
                  }
                  &.ChooseDeliveryDateExpressSlot {
                    padding: 0.3rem 1rem;
                    color: $highlightRed;
                    border: 1px solid $highlightRed;
                    background-color: $highlightRedLighter;
                    border-radius: 4px;
                    cursor: pointer;
                    font-weight: 500;
                    font-size: 12px;
                    display: flex;
                    gap: 0.1rem;
                    flex-direction: column;
                    text-align: center;
                    &:hover {
                      transition: 0.3s;
                      background: $highlightRed;
                      border: 1px solid $highlightRed;
                      color: $white;
                    }
                    &.active {
                      background: $highlightRed;
                      border: 1px solid $highlightRed;
                      color: $white;
                    }
                    span {
                      flex-direction: column;
                    }
                  }
                  &.expressDeliveryFree {
                    color: $highlightRed;
                    font-size: 9px;
                  }
                }
              }
            }
          }
        }

        .PickConvenientTime {
          .PickConvenientTimeTitle {
            padding: 0.5rem 0;
            display: flex;
            gap: 1rem;
            .PickConvenientTimeTitleInner {
              h5 {
                margin-bottom: 0px;
              }
            }
          }
          ul {
            overflow: auto;
            &.PickConvenientTimeSlot {
              // display: flex;
              position: relative;
              gap: 0.6rem;
              li {
                position: relative;
                padding: 0.5rem;
                background: $highlightBlueExtraLighter;
                border: 1px solid $neutral;
                font-size: var(--font-size-regular);
                border-radius: 4px;
                cursor: pointer;
                font-weight: 500;
                // min-width: 118px;
                text-align: center;
                &:hover {
                  transition: 0.3s;
                  background: #5491f5;
                  border: 1px solid #5491f5;
                  color: $white;
                }
                &.active {
                  background: #5491f5;
                  border: 1px solid #5491f5;
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
  .CheckoutShipmentsAction {
    display: flex;
    padding: 1rem;
    border-top: 1px solid $highlightBlueLight;
  }
}
.CheckoutDeliveryScheduledSec {
  .CheckoutShipments {
    padding: 1rem;
    .CheckoutShipmentsInfo {
      padding: 1rem;
      cursor: pointer;
      background-color: #fff4d5;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      font-weight: 500;
      font-size: var(--font-size-regular);
    }
    .CheckoutShipmentsInfoPopup {
      padding: 1rem;
      background-color: #fff4d5;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      gap: 0.75rem;
      font-weight: 500;
      font-size: var(--font-size-regular);
    }
  }
}
.CheckoutDeliveryScheduledLoading {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $highlightBlueExtraLighter;
  .CheckoutShipmentsLoading {
    padding: 1rem;
    background-color: #fff4d5;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: var(--font-size-regular);
  }
}
//  dummy style below
.CheckoutDeliveryRescheduleScheduled {
  display: flex;
}
.CheckoutShipmentReschedule {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.continue {
  width: calc(50% - 0px);
  margin-right: 10px;
  background: #333;
  color: #fff;
  padding: 0.75rem;
  border-radius: 4px;
  font-weight: 700;
}
.skip {
  width: calc(50% - 0px);
  border-radius: 4px;
}

.plzSelectATime {
  margin: 10px 0;
  padding: 0.3rem 0.5rem;
  background-color: #ffe2d5;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  border-radius: 4px;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.bouncingDiv {
  animation: bounce 3s;
}

// popup style

.deliveryScheduledContain {
  padding: 1rem;
  h4 {
    padding-bottom: 1rem;
  }
  p {
    margin: 0.5rem 0;
    &.deliveryScheduledContainNotice {
      background-color: #fff8ec;
      padding: 1rem;
      font-size: var(--font-size-regular);
      margin: 0 0 1rem 0;
      border-radius: 4px;
    }
  }
}

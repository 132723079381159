$color1: #e9e9e9;
$color2: #50c0a8;
$white: #ffffff;
$br4: 4px;
.comments {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  .contain {
    margin: 0.5rem 0;
    height: 100%;
    .commentsCheckBox {
      display: flex;
      gap: 0.5rem;
    }
  }
}

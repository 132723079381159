//
// authentication.scss
//

.auth-bg {
  background-image: url("../../../images/mkt-bg-v1.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  .bg-overlay {
    opacity: 0.9;
  }
  @media (min-width: 768px) {
    height: 100vh;
    
  }

}

.auth-full-page-content {
  min-height: 100vh;
}

// auth 2

.auth-logo {
  .logo-txt {
    color: $dark;
    font-size: 20px;
  }
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
  input[type="input"] + .btn .mdi-eye-outline {
    &:before {
      content: "\F06D1";
    }
  }
}

// for bubble effect
.bg-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  li {
    position: absolute;
    list-style: none;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.1);
    bottom: -50px;
    animation: square 20s infinite;
    transition-timing-function: linear;
    &:nth-child(1) {
      left: 10%;
    }
    &:nth-child(2) {
      left: 20%;
      width: 120px;
      height: 120px;
      animation-delay: 2s;
      animation-duration: 17s;
    }
    &:nth-child(3) {
      left: 25%;
      animation-delay: 4s;
    }
    &:nth-child(4) {
      left: 40%;
      width: 80px;
      height: 80px;
      animation-duration: 22s;
    }
    &:nth-child(5) {
      left: 70%;
      width: 90px;
      height: 90px;
    }
    &:nth-child(6) {
      left: 70%;
      width: 120px;
      height: 120px;
      animation-delay: 3s;
    }
    &:nth-child(7) {
      left: 32%;
      width: 150px;
      height: 150px;
      animation-delay: 7s;
    }
    &:nth-child(8) {
      left: 55%;
      width: 80px;
      height: 80px;
      animation-delay: 15s;
      animation-duration: 40s;
    }
    &:nth-child(9) {
      left: 25%;
      width: 50px;
      height: 50px;
      animation-delay: 2s;
      animation-duration: 40s;
    }
    &:nth-child(10) {
      left: 90%;
      width: 140px;
      height: 140px;
      animation-delay: 11s;
    }
  }
}

@keyframes square {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-1000px) rotate(600deg);
  }
}

body[data-layout-mode="dark"] {
  .auth-logo {
    .logo-txt {
      color: $white;
    }
  }
}
@media (max-width: 768px) {
  .auth-bg {
    display:none !important;
  }
  
}

.my-class{
    background-color:beige;
}
.delivery_dt{
   
    border: 1px solid #bd4a4a;
    width: auto;
    display: inline-block;
    margin-right: 11px;
    padding: 2px 7px;
    border-radius: 3px;
    text-align: center;
    background: #ffd0d0;
    color: #801616;
    margin-left: 10px;
}
.QData{
    white-space: break-spaces;
    max-width: 250px;
    .createdBy{
        font-style: italic;
        color: green;
        font-weight: bold;
    }
}

.parent-class .dropdown-new-menu {
    background-color: white !important;
    top: auto !important;
    bottom: 100% !important;
    transform: translateY(-10px) !important;
    display: block !important; /* Ensure it's visible */
    position: absolute !important; /* Fix its position */
}

.dropdown-new-menu.dropdown-menu.show {
    background-color: white !important;
    top: auto !important;
    bottom: 100% !important;
    transform: translateY(-10px) !important;
}





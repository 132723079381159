.trackStatus {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    margin: 3rem 1.5rem;
    .trackView {
      display: flex;
      gap: 1rem;
      .trackingIcon {
        position: relative;
        &:before,
        &:after {
          content: '';
          display: block;
          width: 2px;
          height: 100%;
          position: absolute;
          background: #333;
          margin-right: -50%;
          z-index: 1;
          left: 50%;
        }
        &:before {
          top: 50%;
          transform: translate(-50%, 0);
        }
        &:after {
          bottom: 39%;
          transform: translate(-50%, -10%);
        }
        span {
          background-color: #949494;
          border-radius: 50%;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 3px solid #fff;
          position: relative;
          z-index: 2;
          &.Active {
            background-color: #50c0a8;
          }
        }
      }
      .trackingStatus {
        p {
          padding: 0px;
          letter-spacing: 0px;
          color: #333333;
          opacity: 1;
          line-height: auto;
          display: flex;
          flex-direction: column;
          margin-bottom: 0px;
          strong {
            letter-spacing: 0px;
            color: #333333;
            opacity: 1;
          }
        }
      }
    }
  
    // .trackView {
    //   &:last-child {
    //     background-color: #50c0a8;
    //     .trackingIcon {
    //       &:before {
    //         display: none;
    //       }
    //     }
    //   }
    // }
  }
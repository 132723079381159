.carousel-control-prev-icon {
    background-image: url("../../assets/images/back.png") !important;
    
    
}
// .card-header {
//     padding: 1rem 1.25rem 0.3rem 4.25rem !important;
   
// }
// .TotalOrderCard .card-body {
//     padding: 10px 2rem !important;
// }
.TotalOrderCard1 .card-body {
    padding: 0px 20px !important;
}
.carousel-control-next-icon {
    background-image: url("../../assets/images/next1.png") !important;
}
.bordertop{
    border-top:2px solid #e9e9ef !important;
    margin: 0 0 10px 0;
}
.Dashboardheader{
    justify-content: center;
    align-items: center;
}

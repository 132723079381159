$color1: #f5f6fa;
$color2: #50c0a8;
$color3: #ced4da;
$white: #ffffff;
$br4: 4px;
.invoiceBreakup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  .orderSummary {
    display: flex;
    gap: 1rem;
    justify-content: center;
    flex-direction: column;
    .orderSummaryInner {
      display: flex;
      flex-direction: column;
      h5 {
        margin-bottom: 0.5rem;
      }
      .orderSummaryValue {
        display: flex;
        gap: 1rem;
        flex-wrap: nowrap;
        justify-content: space-between;
        .orderSummaryLabel {
          display: flex;
          align-items: center;
        }
      }
    }
    .cartProceedToCheckout {
      // max-width: 400px;
      margin: 0 auto;
      width: calc(100% - 2rem);
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
    .orderSummaryTotal {
      display: flex;
      gap: 1rem;
      flex-wrap: nowrap;
      justify-content: space-between;
      border-top: 1px solid $color3;
      align-items: flex-start;
      padding-top: 1rem;
      .orderSummaryLabel {
        display: flex;
        align-items: center;
        gap: 0.75rem;

        label {
          display: flex;
          flex-direction: column;
          gap: 0.2rem;
        }
      }
    }
  }
}

.shippingChargesPopup {
  .shippingChargesContain {
    p {
      &.shippingChargesContainNotice {
        background-color: $color3;
        padding: 0.5rem 1rem;
      }
    }
  }
  .shippingListContain {
    max-height: 30vh;
    overflow-x: hidden;
    overflow-y: auto;
    .shippingList {
      margin-top: 1rem;
      //padding: 0 1rem;
      border-bottom: 1px solid $color1;
      &:last-child {
        border-bottom: none;
      }
      .shippingType {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        .shipped {
          display: flex;
          gap: 0.5rem;
          width: 100%;
          align-items: flex-start;
          .form-check-input {
            margin-top: 0;
          }
        }
        .feeAmount {
          width: 80px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  .shippingTotal {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 1rem 1rem 0;
    border-top: 1px solid $color1;
    .shippingTotalTitle {
      width: calc(100% - 100px);
      font-size: var(--font-size-large);
    }
    .shippingTotalAmount {
      width: 80px;
      display: flex;
      justify-content: flex-end;
      font-size: var(--font-size-large);
    }
  }
  .shippingAction {
    position: relative;
    margin-top: 1rem;
  }
}
.listStyle {
  list-style-type: none !important;
  padding-inline-start: 0.5rem !important;
  padding-inline-end: 0.5rem !important;
}

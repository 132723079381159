$color1: #f5f6fa;
$color2: #50c0a8;
$white: #ffffff;
$br4: 4px;
.paymentMethods {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  .contain {
    border: 1px solid $color1;
    border-radius: $br4;
    margin: 0.5rem 0;
    height: 100%;
    .title {
      background-color: $color1;
      margin: -1.5rem -1.5rem 0.75rem -1.5rem;
    }
    .paymentCheckBox {
      display: flex;
      gap: 0.2rem;
      flex-wrap: wrap;
      span {
        width: 100%;
        display: block;
      }
    }
  }
  .RemoveCod {
    padding-left: 25px;
  }
  .RemoveCod label {
    padding-left: 10px;
  }
}

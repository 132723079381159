.copy-btn{
    background: #469ed7;
    color: #fff;
    font-size: 11px;
    padding: 2px 5px;
    border-radius: 3px;
    cursor: pointer;
    font-weight:600;
}
.copied{
    background: #0ea059;
}
.link-btn{
    margin-left: 6px;
    font-size: 16px;
    vertical-align: sub;
}
.expire-txt{
    float: left;
    width:100%;
    font-size: 10px;
}
.bgWarning{background: #f78989;}
.bgGrey{background: #bebebe;}
.bgSuccess{background: #6aad81;}
.invoiceBtn{
    background: #414141;
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
    font-weight: 500;
}
.validationError{
    font-size: 11px; color: #b12d39;;
}


.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-right___h_uSC-sass {
  width: 700px;
}

.askHelpPopup {
  .askHelpContain {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
   .askHelpSection{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding:0 1rem 0 1rem;
   }
    .askHelpMessageContain {
      padding: 0rem;
      height: 100vh;
      overflow-y: auto;
      position: relative;
      .askHelpRefId {
        position: sticky;
        top: 0px;
        padding: 0.5rem 1rem;
        width: calc(100% - 2rem);
        background: #f9f8f2 0% 0% no-repeat padding-box;
        font-weight: normal;
      }
      .askHelpMessage {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .customerMessage {
          display: flex;
          gap: 1rem;
          .icon {
        
            font-size: 37px;
            font-weight: bolder;
           
          }
          .contentData{
            display: flex;
            gap: 1rem;
            flex-direction: column;
            align-items: flex-start;
            .message {
             background-color: #F5F5F5;;
              padding: 0.75rem;
              border-radius: 10px;
              display: flex;
              gap: 0.5rem;
              flex-direction: column;
              p {
                line-height: 18px;
                margin-bottom: 0px !important;
              }
              .time {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                font-size: 12px;
                color: var(--primary);
              
              }

              &.read {
                .time {
                  svg {
                    path {
                      fill:  #179fee;
                    }
                  }
                }
              }
            }
          }
         
         .rplyButton{
         display:flex;
         justify-content: flex-end;
         background: #F0FCF8 0% 0% no-repeat padding-box;
         border: 1px solid #50C0A8;
         border-radius: 4px;
         opacity: 1;
         font-size: 14px;
         font-weight: medium;
         letter-spacing: 0.28px;
         opacity: 1;
         padding:1px 6px;
        }
     
      }

        .replayMessage {
          display: flex;
          gap: 1rem;
          .icon {
            font-size: 37px;
            font-weight: bolder;
        
          }
          .contentData {
            display: flex;
            gap: 1rem;
            flex-direction: column;
            align-items: flex-end;
            .message {
              background-color: #F5F5F5;
              padding: 0.75rem;
              border-radius: 10px;
              display: flex;
              gap: 0.5rem;
              flex-direction: column;
              p {
                line-height: 18px;
              }
              .time {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                font-size: var(--font-size-small-l);
                color: var(--primary);
                svg {
                  path {
                    fill: var(--neutralDark);
                  }
                }
              }
              &.read {
                .time {
                  svg {
                    path {
                      fill:  #179fee;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .askHelpTextArea {
      padding: 1rem;
      position: relative;
      textarea {
        height: 200px;
      }
      .sendBtn {
        
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
      
      }
    }
  }
}
.breaker {  height: 13px !important;
max-width: 100%;
width: 100%;
border: none;
margin: 1rem auto;
background: #bdbdc0;
box-shadow: inset 0px 3px 8px #ebebeb;
}

$color1: #e9e9e9;
$color2: #50c0a8;
$white: #ffffff;
$br4: 4px;
.customerOrderingMode {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  .contain {
    border: 1px solid $color1;
    border-radius: $br4;
    margin: 0.5rem 0;
    height: 100%;
    .promoBtn {
      position: absolute;
      top: 24px;
      right: 10px;
      &.Close {
        background-color: transparent;
        border: none;
      }
    }
    .success {
      color: $color2;
    }
  }
}

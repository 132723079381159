.swiper-button-next,
.swiper-button-prev {
  color: #000 !important;
}
.productCard {
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid #f7f8fb;
  .productImage {
    background-color: #f6f6f6;
    img {
      mix-blend-mode: multiply;
    }
  }
  .productDetails {
    padding: 0.5rem;
    p {
      &.productName {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: unset;
        min-height: 36px;
        margin-bottom: 0.25rem;
      }
      &.productQty {
        font-weight: bold;
        margin: 0px;
        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
        gap: 0.5rem;
        font-size: 11px;
        background: #f7f8fb;
        padding: 0.25rem 0.5rem;
        border-radius: 6px;
        span {
          width: calc(100% - 36px);
        }
      }
    }
  }
}

$color1: #e9e9e9;
$color2: #289be8;
$color3: #fff8ec;
$color4: #131523;
$white: #ffffff;
$br4: 4px;
.assembly {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  .contain {
    border: 1px solid $color1;
    border-radius: $br4;
    margin: 0.5rem 0;
    height: 100%;
    .form-check-input {
      margin-top: 0.5rem;
    }
    .form-check {
      p {
        span {
          color: $color2;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      label {
        font-size: 16px;
        color: $color4;
      }
    }
  }
}
.assemblyChargesPopup {
  .assemblyChargesContain {
    p {
      &.assemblyChargesContainNotice {
        background-color: $color3;
        padding: 0.5rem 1rem;
      }
    }
  }
  .assemblyListContain {
    max-height: 30vh;
    overflow-x: hidden;
    overflow-y: auto;
    .assemblyList {
      margin-top: 1rem;
      padding: 0 1rem;
      border-bottom: 1px solid $color1;
      &:last-child {
        border-bottom: none;
      }
      .assemblyType {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        .shipped {
          display: flex;
          gap: 0.5rem;
          width: 100%;
          align-items: flex-start;
          .form-check-input {
            margin-top: 0;
          }
        }
        .feeAmount {
          width: 80px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
  .assemblyTotal {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 1rem 1rem 0;
    border-top: 1px solid $color1;
    .assemblyTotalTitle {
      width: calc(100% - 100px);
      font-size: var(--font-size-large);
    }
    .assemblyTotalAmount {
      width: 80px;
      display: flex;
      justify-content: flex-end;
      font-size: var(--font-size-large);
    }
  }
  .assemblyAction {
    position: relative;
    margin-top: 1rem;
  }
}

.checkbox {
  border-radius: 5px;
  width: 16px;
  height: 16px;
  border: solid 2px #827f7f;
  display: flex;
  align-items: center;
  justify-content: center;
  &.selected {
    background-color: #3a3a3a;
  }
}
.assemblyChargesContainNotice {
  strong {
    width: calc(100% - 100px);
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    &.assemblyChargesPopup {
      max-width: 600px !important;
      margin: 1.75rem auto;
    }
  }
}

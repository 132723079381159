@import './icons.scss';

// Light Theme
@import './bootstrap.scss';
@import './app.scss';

// RTL
// @import "custom/rtl/general-rtl";
// @import "custom/rtl/bootstrap-rtl";
// @import "custom/rtl/spacing-rtl";
// @import "custom/rtl/float-rtl";
// @import "custom/rtl/text-rtl";
// @import "custom/rtl/structure-rtl";
// @import "custom/rtl/plugins-rtl";
// @import "custom/rtl/components-rtl";
// @import "custom/rtl/pages-rtl";

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.table td {
  font-size: 13px;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  background-color: #131523;
  border-color: #131523;
}
.form-select:focus {
  box-shadow: 0 0 0 0.15rem #50c0a861;
}

/* close button **/
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width
    auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}
.card {
  border: 1px solid #e9e9ef;
  box-shadow: none !important;
}
.card.card-dh {
  border: 1px solid #e9e9ef;
  box-shadow: none;
}
/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}
.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}
.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}
.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}
.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.ReactModal__Overlay {
  z-index: 5000 !important;
}
.rangeslider__handle:focus {
  outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}
// .search-label {
//   float: right;
// }
.react-drawer-overlay {
  z-index: 9999;
}

/* rating**/
.rating-container {
  background-color: transparent !important;
}

/* form editor **/
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}

.search-box .search-icon-search {
  font-size: 16px !important;
  position: absolute !important;
  left: 13px !important;
  top: 0 !important;
  line-height: 38px !important;
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #5156be !important;
}
.preview-thumbsnav {
  display: flex !important;
  list-style-type: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: #5156be;
}
.flatpickr-calendar.arrowBottom:after {
  border-top-color: #5156be;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #5156be;
}
.dataTblHeader {
  display: flex;
  justify-content: flex-end;

  .searchBox {
    .search-input-container {
      position: relative;
    }
    .searchIcon {
      margin-top: -11px;
      position: absolute;
      top: 50%;
      right: 10px;
    }
  }
}
.dataTblFooter {
  display: flex;
  justify-content: space-between;
  margin: 25px 0;
  gap: 1rem;

  .dropdown-menu.show {
    top: inherit !important;
    bottom: 100%;
  }
}
.textAlignRight {
  text-align: right !important;
}
.textAlignRight1 {
  display: flex !important;
  justify-content: right !important;
}
.btn-sm {
  line-height: 15px;
}

.bg-lightgrey {
  background-color: #dadbdc;
  color: #5b5b5b;
}
.dt-table {
  position: relative;
}
.sploader {
  position: absolute;
  left: 50%;
  margin-left: -25px !important;
  top: 50%;
  margin-top: -25px !important;
}
.vertical-menu,
.navbar-brand-box {
  background: #f5f6fa;
}
.card {
  box-shadow: 0px 1px 4px #15223214;
  border: 1px solid #f7f8fb;
  border-radius: 6px;
}
.btn-secondary {
  // box-shadow: 0 2px 6px 0 rgb(116 120 141 / 50%);
  margin: 0px;
}
.DashbordFliter .btn-group {
  background: #d7dbec;
  padding: 3px;
  border-radius: 8px;
}
.InputBg {
  padding: 2px;
  border-radius: 8px;
  position: relative;
}
.InputBg1 {
  background: #d7dbec;
  padding: 2px;
  border-radius: 8px;
  position: relative;
  width: 50%;
}
.DashbordFliter .btn.btn-secondary {
  margin-right: 2px;
  background-color: #ffffff;
  color: #333333;
  border: #ffffff;
}
.DashbordFliter .btn.btn-secondary:last-child {
  margin-right: 0px !important;
}
.DashbordFliter .btn-check:checked + .btn-secondary,
.DashbordFliter .btn-check:active + .btn-secondary,
.DashbordFliter .btn-secondary:active,
.DashbordFliter .btn-secondary.active,
.DashbordFliter .show > .btn-secondary.dropdown-toggle {
  background: #333333;
  color: #ffffff;
  margin-left: 0 !important;
  border: 2px;
  margin-right: 2px;
  box-shadow: none;
}
.DashbordFliter .btn-group > .btn:not(:first-child),
.DashbordFliter .btn-group > .btn-group:not(:first-child) {
  margin-left: 0px;
}
.card-header .form-select {
  max-width: 150px;
  border: transparent;
}
.InputBg .bx-calendar {
  position: absolute;
  background: #fff;
  top: 10px;
  left: 10px;
  color: #333333;
  font-size: 22px;
  pointer-events: none;
  z-index: 9;
}
.InputBg .flatpickr-input[readonly] {
  background-color: #fff;
  padding-left: 35px;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #c00d0d;
}
.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background-color: #ffffff;
  outline: none;
  font-size: 14px;
}
.DashbordAuto {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 50px;
  height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 32px;
  color: #5a607f;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border: 1px solid #d0d5e8;
  border-radius: 50%;
  margin-right: 16px;
  vertical-align: middle;
  background: #cad0e93b;
}
.text-muted {
  color: #5a607f !important;
}
.card-header {
  padding: 1rem 1.25rem 0.3rem 1.25rem;
  border-bottom: none;
}
.noBorder {
  border: none;
}
.TotalOrderCard .card-body {
  padding: 0 1.25rem;
}
.TotalOrderCard .table thead {
  background: #ffffff;
}
.TotalOrderCard .table > :not(caption) > * > * {
  padding: 0.3rem 0;
  border: none;
}
.TotalOrderCard td:last-child,
.TotalOrderCard th:last-child {
  text-align: right;
}
.TotalOrderCard1 td:last-child,
.TotalOrderCard1 th:last-child {
  text-align: right;
}
.Border {
  border-top: 2px solid #e9e9ef !important;
  padding: 1rem 0 !important;
}
.TotalOrderCard tr:first-child td {
  padding-top: 0.5rem;
}
.card-title {
  color: #131523;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #131523;
}
.navitemalign {
  text-align: left !important;
}
.star1 {
  background-color: #fbab32 !important;
}
.deliverybtn {
  padding: 4px 5px 4px 5px;
  border: 1px solid #50c0a8;
  background: #e1fff8 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #50c0a840;
  border: 1px solid #50c0a8;
  border-radius: 13px;
  opacity: 1;
  color: #424242;
  text-align: center;
  font-size: 12px;
}
.deliverybtn1 {
  padding: 4px 5px 4px 5px;
  border: 1px solid red;
  background: #fef9f9 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #50c0a840;
  border: 1px solid red;
  border-radius: 13px;
  opacity: 1;
  color: #424242;
  text-align: center;
  font-size: 12px;
}
.footer {
  font-size: 12px;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: #c00d0d;
}
.OrderList button.btn {
  padding: 0px;
  line-height: initial;
  height: auto;
  display: block;
  width: 100%;
}
button.btn.active,
button.btn.active h4 {
  color: #ef504e;
}

.OrderList h4 {
  margin: 0.5rem 0 0 0px;
  padding: 0px;
}
.OrderList .btn-check:focus + .btn,
.OrderList .btn:focus {
  outline: 0;
  box-shadow: none;
}
.InputBgBtn {
  background: #c00d0d;
  padding: 2px;
  border-radius: 8px;
  position: relative;
}
.active.card {
  box-shadow: 0px 1px 4px #f4e5e8 !important;
  border: 3px solid #ef504e !important;
}
.card {
  border: 3px solid #f7f8fb;
}
.search-input-container {
  position: relative;
}
.search-input-container span {
  position: absolute;
  top: 8px;
  right: 8px;
}
.OrderListTable {
  box-shadow: 0px 1px 4px #15223214;
  border: 3px solid #f7f8fb;
}
thead {
  background: #f5f6fa;
}
.searchBox .form-control {
  background-color: #ffffff;
  border: 1px solid #cccccc;
}
.form-group .form-control {
  border-color: #eeeff1 !important;
  border-radius: 0.31rem !important;
}
.DashbordFliter .btn-primary {
  max-height: 60px;
}
.DashbordFliter {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}
.DashbordFliter1 {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}
// .btn-check:checked + .btn-primary:focus,
// .btn-check:active + .btn-primary:focus,
// .btn-primary:active:focus,
// .btn-primary.active:focus,
// .show > .btn-primary.dropdown-toggle:focus {
//   box-shadow: 0 0 0 0.15rem #33333363;
// }
// .btn-check:checked + .btn-primary,
// .btn-check:active + .btn-primary,
// .btn-primary:active,
// .btn-primary.active,
// .show > .btn-primary.dropdown-toggle {
//   color: #fff;
//   background-color: #333333;
//   border-color: #333333;
// }
// .btn-check:focus + .btn-primary,
// .btn-primary:focus {
//   color: #fff;
//   background-color: #333333;
//   border-color: #333333;
//   box-shadow: 0 0 0 0.15rem #33333363;
// }

.SecondaryBtn2 {
  margin-right: 3px;
  background-color: #ffffff;
  color: #333333;
  border: #ffffff;
  padding: 0.47rem 0.75rem;
  border-radius: 4px;

  &:hover {
    background: #131523;
    color: #ffffff;
  }
  &:last-child {
    margin-right: 0px;
  }
}
// .btn-primary {
//   box-shadow: none;
//   color: #fff;
//   background-color: #333333;
//   border-color: #333333;

//   &:hover {
//     background: #131523;
//     border-color: #131523;
//     color: #ffffff;
//   }
// }
// .btn-secondary {
//   box-shadow: none;
//   color: #fff;
//   background-color: #333333;
//   border-color: #333333;

//   &:hover {
//     background: #131523;
//     border-color: #131523;
//     color: #ffffff;
//   }
// }
.app-search .btn {
  background-color: #dc3545;
  border-color: #dc3545;

  &:hover {
    background: #c02a38;
    border-color: #c02a38;
    color: #ffffff;
  }
}
// .ProgressStep-0 {
//   left: 0% !important;
// }
// .ProgressStep-1 {
//   left: 25% !important;
// }
// .ProgressStep-2 {
//   left: 50% !important;
// }
// .ProgressStep-3 {
//   left: 75% !important;
// }
// .ProgressStep-4 {
//   left: 100% !important;
// }
// .ProgressSteps-0 {
//   left: 0% !important;
// }
// .ProgressSteps-1 {
//   left: 20% !important;
// }
// .ProgressSteps-2 {
//   left: 40% !important;
// }
// .ProgressSteps-3 {
//   left: 60% !important;
// }
// .ProgressSteps-4 {
//   left: 80% !important;
// }
// .ProgressSteps-5 {
//   left: 100% !important;
// }

// .ProgressStepTop-0 {
//   top: 4px !important;
// }
// .progress-bar.bg-primary {
//   background-color: #50c0a8 !important;
// }
// .ProgressStep.btn-primary {
//   background-color: #50c0a8 !important;
//   box-shadow: none;
//   border-color: #5eb19f;
// }
// .ProgressStep.btn-cancel {
//   background-color: #dc3545 !important;
//   box-shadow: none;
//   border-color: #dc3545;
// }
// .progress-bar.bg-cancel {
//   background-color: #dc3545 !important;
// }
// .ProgressStep.btn-secondary {
//   background-color: #f5f6fa !important;
//   box-shadow: none;
//   border-color: #f5f6fa;
// }
// .ProgressStep.btn-secondary svg path {
//   color: #d5d9e8;
//   stroke: #d5d9e8;
// }
// .OrderDetailsBox .card-body {
//   padding: 0rem 1.25rem 1.25rem;

// }
.OrderDetailsBox1 {
  border: 2px solid #50c0a8;
  border-radius: 4px;
  opacity: 1;
}
.OrderDetailsBox2 {
  background-color: #e7fffa !important;
}
.VendorDetail {
  display: flex;
  gap: 1rem;
  align-items: center;
  align-content: flex-start;
}
// .status {
//   font-size: 12px;
//   padding: 3px 6px;
//   border-radius: 4px;
//   margin-top: 15px;
// }
.pending {
  background-color: #f2f4f8;
  color: #172026;
}
.sucess {
  background-color: #ebf9f4;
  color: #56c099;
}
.scheduled {
  background-color: #ffb311;
  color: #fff7e6;
  text-decoration: none;
}
.posted {
  background-color: #f2f4f8;
  color: #1b4680;
}
.failed {
  background-color: #fdf4f6;
  color: #e15e7d;
}
.LocationMap {
  color: #1170ff;
  text-decoration: underline !important;
}
.InvoiceBreakUp.card {
  background: #f5f6fa;
}
.InvoiceBreakUp .card-body {
  padding: 0 1.25rem;
}
.InvoiceBreakUp .table > :not(caption) > * > * {
  padding: 0.3rem 0;
  border: none;
}
.InvoiceBreakUp td:last-child,
.InvoiceBreakUp th:last-child {
  text-align: right;
}
.InvoiceBreakUp .card-header {
  background-color: #f5f6fa;
}
.OrderItemDetails .card-body,
.OrderNotebyAdmin .card-body {
  padding: 0px;
}

.ProductDetails {
  display: flex;
  gap: 1rem;
}

// .ProductDetails p {
//   max-width: 308px;
// }
.CityMissing small {
  color: #e15e7d;
  font-size: 12px;
}
.UpdateDetailsForm .card-body {
  padding-top: 0px;
}
.UpdateDetailsForm label {
  font-weight: 400;
  font-size: 13px;
}
.UpdateDetailsForm .form-select {
  font-size: 13px;
}
.UpdateDetailsForm .btn {
  width: 100%;
}
.DashbordFliter
  .css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-right___h_uSC-sass {
  width: 700px;
}
.WalletDetails.card {
  box-shadow: none;
  border: none;
  border-radius: 0;
}
.WalletDetails .card-header {
  text-align: center;
}
.WalletDetails .card-header p {
  margin: 0px;
  padding: 0px;
}
.WalletDetails .card-body {
  padding: 0px;
  overflow: auto;
  height: calc(100vh - 110px);
}
.WalletDetailsBlock {
  background: #f3f3f9;
  padding: 15px 20px;
  margin-bottom: 0.5rem;
}
.WalletDetailsBlock p {
  margin: 0px;
}
.WalletDetailsBlock h5,
.WalletDetailsBlock p {
  display: flex;
  justify-content: space-between;
}
.WalletDetailsBlock h5 {
  font-size: 15px;
}
.WalletDetailsBlock p {
  font-size: 13px;
}
.WalletDetailsBlock p.CreditNote {
  font-size: 12px;
  padding-top: 5px;
  border-top: 1px solid #e1e1e1;
  margin-top: 5px;
}
.WalletDetailsBlock .bx {
  background: #fff;
  border-radius: 100%;
  color: #fff;
  margin-right: 5px;
}
.WalletDetailsBlock .bx.bx-plus {
  background: #50c0a8;
}
.WalletDetailsBlock .bx.bx-minus {
  background: #d12e27;
}
// .ProgressStep {
//   width: 3rem;
//   height: 3rem;
//   text-align: center;
// }
// .m-5.ProgressStepBlock {
//   margin: 3rem 0 !important;
// }
// .status.ProgressStep {
//   width: 6rem;
//   height: auto;
// }
// .ProgressbarStatus {
//   margin: 25px 0;
// }
.customerButton {
  text-align: right;
}
@media (max-width: 767px) {
  .page-content {
    padding: calc(70px + 0px) calc(0px / 2) 60px calc(0px / 2);
  }
  .verification {
    input {
      width: 30px !important;
    }
  }
  .customerButton {
    margin-top: 20px;
  }
  .refundOrder .react-drawer-drawer {
    width: 80% !important;
  }
  .trackingOrder .react-drawer-drawer {
    width: 80% !important;
  }
  .NonService .react-drawer-drawer {
    width: 80% !important;
  }
  .row-fluid-scroll {
    overflow-x: scroll;
    flex-wrap: nowrap;
    margin-top: 15px;
  }
  .row-fluid-scroll > * {
    width: 82%;
  }

  .OrderList.row-fluid-scroll > * {
    width: 35%;
  }
  b,
  strong {
    font-weight: 600;
    color: #131523;
  }
  .card.card-dh {
    border: none;
  }
  .card.card-dh .card-body.card-body-dh {
    padding: 0px;
    margin-top: 25px;
  }
  .page-title-box {
    padding-top: 20px;
  }
  .ProgressbarStatus {
    margin: 30px 10px;
  }
  .ProgressStep {
    width: 1.8rem;
    height: 1.8rem;
    padding: 0px;
  }
  span.ProgressStep {
    width: 3rem;
    height: 3.8rem;
    font-size: 10px;
    text-align: center;
  }
  .d-lg-none {
    display: none !important;
  }
  .page-title-box .breadcrumb {
    font-size: x-small;
  }
  .css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-right___h_uSC-sass {
    width: 312px !important;
  }
  .MobileView {
    margin-top: 10px;
  }
  .nav-tabs-custom.card-header-tabs {
    padding-bottom: 10px;
  }
}
@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}
.cntsloader {
  position: absolute;
  left: 50%;
  margin-left: -10px !important;
  top: 50%;
  margin-top: 25px !important;
}
// .cntsHeight{min-height: 90px;}

// .react-drawer-drawer{
//   width: 80% !important;
// }
.refundOrder .react-drawer-drawer {
  width: 30%;
}
.trackingOrder .react-drawer-drawer {
  width: 20%;
}
.NonService .react-drawer-drawer {
  width: 30%;
}

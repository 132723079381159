.Dashboard {
  hr {
    background-color: #f7f8fb;
    opacity: 1;
  }
  .bdr {
    border-left: 1px solid #f7f8fb;
    border-right: 1px solid #f7f8fb;
  }
  .dashboardCardBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .dashboardCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 0.5rem;
      position: relative;
      .refresh {
        position: absolute;
        top: 0px;
        right: 0px;
        cursor: pointer;
      }
      h2 {
        padding: 0px;
        margin: 0px;
        font-size: 48px;
      }
      h3 {
        padding: 0px;
        margin: 0px;
        font-size: 28px;
        &.successOrder {
          color: #20cb81;
        }
        &.failedOrder {
          color: #ef5049;
        }
        &.notFlowOrder {
          color: #f29313;
        }
      }
      p {
        padding: 0px;
        margin: 0px;
      }
      a {
        text-decoration: underline !important;
        color: var(--bs-body-color);
      }
    }
  }
  .productList {
    display: flex;
    gap: 0.5rem;
    overflow-y: auto;
  }
}

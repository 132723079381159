@import '../../../../../../src/styles/media.scss';
$color1: #e9e9e9;
$color2: #50c0a8;
$color3: #ced4da;
$color4: #333333;
$white: #ffffff;
$br4: 4px;
.addAddress {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  .contain {
    border: 1px solid $color1;
    border-radius: $br4;
    margin: 0.5rem 0;
    height: 100%;
    .addNewLocationMap {
      padding: 0;
      margin: 1rem 0 0;
      position: relative;
      .addNewLocationGoogleMap {
        width: 100%;
        height: 500px;
        position: relative;
        .closeBtn {
          top: 20px;
        }
        .LocationGoogleMap {
          width: 100%;
          height: 100%;
          position: relative;
          overflow: hidden;
          background: #ccc;
          border-radius: $br4;
          overflow: hidden;
        }
      }
      .addNewLocationMapInput {
        position: absolute;
        top: 0px;
        left: 0px;
        width: calc(100% - 1.2rem);
        padding: 0.75rem;
        background: $color3;
        z-index: 1;
        input {
          width: calc(100% - 1rem);
          height: 38px;
          border: 1px solid $color3;
          border-radius: $br4;
          padding: 0.5rem;
          text-indent: 8px;
        }
        .close {
          right: 30px;
          top: 8px;                
        }
      }
      .mapErrorSaveContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .error {
          text-align: right;
        }
      }
    }

    // Address Form
    .addNewAddressFormSection {
      display: flex;
      gap: 0rem;
      padding: 0;
      margin: 1rem 0 0;
      position: relative;
      .addNewAddressLocationMap {
        width: 50%;
        border-radius: $br4;
        position: relative;
        display: flex;
        align-items: center;
        background: #ccc;
        overflow: hidden;
        .gMap {
          width: 100%;
          height: 100%;
        }
        .addNewLocationMapInput {
          position: absolute;
          top: 0px;
          left: 0px;
          width: calc(100% - 0rem);
          padding: 0.75rem;
          background: $color3;
          z-index: 1;
          .addNewLocationMapInputValue {
            width: calc(100% - 0rem);
            height: 36px;
            border: 1px solid $color3;
            background-color: $white;
            border-radius: $br4;
            padding: 0.5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .changeBtn {
            top: 16px;
            right: 16px;
            position: absolute;
            background-color: $white;
            padding: 3px 8px;
            border-radius: $br4;
            border: 1px solid $color3;
            cursor: pointer;
            &:hover {
              box-shadow: 0 4px 12px 0 rgb(0 0 0 / 5%);
              background-color: $color4;
              color: $white;
            }
          }
        }
      }
      .addNewAddressForm {
        width: calc(50% - 0rem);
        padding: 2rem;
      }
      /*1129 Added*/
      .gMap {
        width: 100%;
        height: 100%;
      }
      .buttonContainSave {
        width:100%;
      }
      
    }
    .close{
      padding:1.9rem 2rem !important;
      
    }
  }
}
.inputStyle {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 98%;
  height: 41px;
  padding: 0 12px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses;
  position: absolute;
  top: 10px;
  right: 1%;
  left: 1%;
};

$color1: #03a685;
$color2: #029174;
$color3: #f6f6f8;
$color4: #ebebeb;
$white: #ffffff;
.createNewOrderBtn {
  background-color: $color1 !important;
  color: $white !important;
  &:hover {
    background-color: $color2 !important;
  }
}
.createNewOrderPopup {
  .card-header {
    border-bottom: 1px solid var(--rs-border-primary);
  }
  .card {
    border: none;
    margin-bottom: 0px;
    label,
    p {
      font-size: 14px;
      strong {
        font-size: 16px;
        font-weight: 700;
      }
      &.title {
        margin-bottom: 15px;
      }
    }
  }
  hr {
    &.divider {
      height: 0.75rem;
      margin: 1rem -1.25rem;
      background-color: $color3;
      box-shadow: inset 0 3px 8px $color4;
      opacity: 1;
    }
  }
  .btn-primary {
    font-size: 16px;
    font-weight: 700;
  }
  .card-body{
    text-align: left;
    padding-left: 2em;;
  }
  .card-header h5{
    text-align: left;
  }
}

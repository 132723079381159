.trendingBody {
  padding: 0px !important;
  .trendingContain {
    hr {
      padding: 0px !important;
      background-color: #f9f9fb;
      opacity: 1;
      margin-bottom: 0;

      &.horizontal {
        height: 4px;
      }
      &.vertical {
        width: 4px;
        height: 85px;
        margin-bottom: 0px;
      }
    }
    .androidIcon,
    .appleIcon,
    .desktopIcon,
    .cellphoneIcon {
      font-size: 28px;
      align-items: center;
      gap: 0.5rem;
    }
    .deviceType {
      font-size: 11px;
      text-align: center;
    }
    .trendingTitle {
      strong {
        font-size: 32px;
        line-height: 32px;
      }
      .title {
        line-height: 14px;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .trendingDeviceTitleLabel {
      line-height: 12px;
      font-size: 11px;
      font-weight: 600;
      color: #949494;
    }
    .trendingDeviceTitle {
      color: var(--bs-body-color);

      strong {
        font-size: 22px;
        line-height: 22px;
      }
      small {
        font-size: 9px;
      }
    }
    .trending,
    .trendingDevice {
      span {
        border-radius: 6px;
        font-size: 8px;
        line-height: 8px;
        min-width: 70px;
      }
      small {
        font-size: 9px;
      }
    }
    .trendingIcon {
      font-size: 32px;
    }
    .adVsOrganic {
      padding: 0.75rem;
      margin: 0 -6px -4px 0;
      border-bottom: 4px solid #f9f9fb;
      border-right: 4px solid #f9f9fb;
    }
  }
}
@media (max-width: 767px) {
  .trendingBody {
    .trendingContain {
      .androidIcon,
      .appleIcon,
      .desktopIcon,
      .cellphoneIcon {
        font-size: 22px;
        justify-content: flex-start;
        background: #f9f9fb;
        padding: 0 0.5rem;
        border-radius: 4px;
        margin: 0px calc(var(--bs-gutter-x) - 2rem) 0.5rem;
      }
      .trending,
      .trendingDevice {
        span {
          min-width: 36px;
          span {
            display: none;
          }
        }
      }
      .trendingTitle {
        font-size: 11px;
        strong {
          font-size: 24px;
        }
      }
      hr {
        &.vertical {
          height: 96px;
        }
      }
      .trendingDeviceTitle {
        .title {
          font-size: 11px;
        }
      }
    }
  }
}
.language {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: auto;
  gap: 0.5rem;
  justify-content: flex-end;
}

.my-class {
  background-color: #dc08080f;
}
.delivery_dt {
  border: 1px solid #bd4a4a;
  width: auto;
  display: inline-block;
  margin-right: 11px;
  padding: 2px 7px;
  border-radius: 3px;
  text-align: center;
  background: #ffd0d0;
  color: #801616;
  margin-left: 10px;
}
.queueListProgress {
  .progress-bar {
    height: 100%;
    align-items: flex-end;
  }
}

.installPlan {
  float: left;
  font-style: italic;
  font-size: 10px;
  background: #d3e6f4;
  padding: 0px 5px;
  line-height: 18px;
  margin-top: 6px;
  border-radius: 3px;
}
.mdi-arrow-down {
  padding-left: 3px;
  color: rgb(22, 139, 189);
}
.ui.fluid.dropdown {
  display: block;
  width: 14%;
  min-width: 0;
}
.NavWhitespace {
  white-space: nowrap;
}

@media (min-width: 320px) and (max-width: 1490px) {
  .scrollNav {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  *::-webkit-scrollbar {
    width: 12px;
  }

  .NavWhitespace {
    white-space: nowrap;
  }
}
@media (max-width: 480px) {
  .DropdownItem1 {
    font-size: 0.3rem;
  }
  .img-thumbnail {
    max-width: 100px !important;
  }
  .ProductDetailsSec {
    .ProductDetails {
      .itemDetail {
        span {
          min-width: 345px;
        }
      }
    }
  }
}

//**----By Bari--**//
.postInfo {
  font-size: 11px;
}
.ProductDetails img {
  border-radius: 0.75rem;
}

.filterTxt {
  color: rgb(87, 85, 85);
  cursor: pointer;
  border: #ccc solid 1px;
  padding: 8px;
  line-height: 29px;
  border-radius: 4px;
  margin: 0px 10px;
  background: white;
  font-weight: 500;
}
.filterTxt1 {
  color: rgb(22, 139, 189);
  cursor: pointer;
  line-height: 29px;
  font-size: 10px;
  border-radius: 3px;
  padding: 3px;
  border: 1px solid rgb(22, 139, 189);
}

.btnLocation {
  position: absolute;
  right: 102px;
  bottom: 35px;
}

.bHNpMt button {
  flex: 0 0 auto;
  width: 80px;
  position: relative;
}
.bHNpMt button span {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 12px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  padding: 2px 0px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #000000 !important;
}
.btnTracking {
  background: #f0fcf8 0% 0% no-repeat padding-box;
  border: 1px solid #50c0a8;
  border-radius: 4px;
  opacity: 1;
  font-size: 14px;
  font-weight: medium;
  letter-spacing: 0.28px;

  opacity: 1;
  padding: 1px 6px;
  a {
    color: #333333 !important;
  }
}
.btnTrackingWidth {
  width: 55px;
}
.btnTracking1 {
  background: #eeeeee 0% 0% no-repeat padding-box;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  opacity: 1;
  font-size: 14px;
  font-weight: medium;
  letter-spacing: 0.28px;
  color: #333333;
  opacity: 1;
}
.btnSpacing {
  margin: 10px 0px;
}

.ProductDetailsSec {
  max-width: 500px;
  .ProductDetails {
    .itemDetail {
      width: calc(100% - 118px);
      span {
        display: block;

        width: 100%;
      }
    }
  }
}
.ProductDetailsSec {
  max-width: 500px;
  .ProductDetails {
    .itemDetail {
      width: calc(100% - 118px);
      span::after {
        content: '\a';
        white-space: pre;
      }
    }
  }
}
.orderError {
  padding: 11px;
  width: 100%;
  color: #000;
  background-color: #f3d8d8;
  text-align: center;
}
.ProductDetailsSec {
  .itemDetail1 {
    span {
      display: block;

      width: 100%;
    }
    .counterdesign {
      border: 1px solid #e5e5ea;
      max-width: 102px;
      border-radius: 3px;
      padding-top: 3px;
      padding-bottom: 3px;
      span {
        display: inline-block;
        width: 30px;
      }
    }
  }
}
// tr td > div {
//   display: flex;
//   align-items: center;
// }
// tr td div div{
//     padding:10px;
// }
// tr td div span{
//     display :block;
// }
// Table tr {
//     white-space: nowrap;
// }

@media (max-width: 1550px) {
  .scrollShipNav {
    overflow-y: scroll;
    height: 700px;
  }
}

@media (min-width: 1551px) and (max-width: 2500px) {
  .scrollShipNav {
    overflow-y: scroll;
    height: 960px;
  }
}
.searchBar {
  width: 45px !important;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 20px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}
.searchBar:focus {
  width: 100% !important;
}
.search-box .form-control .searchBar {
  padding-left: 30px;
}
.search-box .form-control {
  padding-left: 30px !important;
}

.CrossIcon {
  width: 17px !important;
  filter: invert(22%) sepia(64%) saturate(7206%) hue-rotate(354deg)
    brightness(104%) contrast(128%);
  padding-left: 5px;
}
.TickIcon {
  width: 22px !important;
  filter: invert(58%) sepia(45%) saturate(699%) hue-rotate(68deg)
    brightness(96%) contrast(82%);
}

.DeliverySlotSelection .react-drawer-overlay {
  pointer-events: none;
}
.analytics {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  .analyticsList {
    border: 1px solid var(--rs-gray-300);
    padding: 2px 5px 2px 5px;
    border-radius: 4px;
    font-size: xx-small;
  }
  .showMoreButton {
    float: left;
    font-size: 11px;
    color: #1f8c51;
    font-weight: 600;
    cursor: pointer;
  }
}

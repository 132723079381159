.loadingContain {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgb(255 255 255 / 85%);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.innerLoadingContainer {
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
  background-color: rgb(255 255 255 / 85%);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addToCartLoadingContainer {
  position: absolute;
  top: -1px;
  right: -1px;
  left: -1px;
  bottom: -1px;
  background-color: var(--primaryLight);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-small);
}
.alignCenter {
  margin: 0 45%;
}

.deliveryAlertBx{
    border: #bd4a4a solid 1px;
    width: auto;
    display: inline-block;
    margin-right: 10px;
    padding: 2px 6px;
    border-radius: 3px;
    text-align: center;
    background: #ffd0d0;
    color: #801616;
    span{
        font-size: 10px;
        line-height: 11px;
        float: left;
    }
}
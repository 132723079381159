.modal-footer{
  display:flex;
  justify-content: space-between;
}

.dropzone-previews {
  h3 {
    width: 100%;
    font-size: 16px;
  }
  .uploadedImgContainer {
    display: flex;
    gap: 10px 6px;
    flex-wrap: wrap;
    max-height: 200px;
    overflow: auto;
    .dz-image-preview {
      width: 5%;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}
.dropzone-error{
    border: 2px #ff0000 dashed !important;
    animation: blink 1s;
    animation-iteration-count: 3;
}
@keyframes blink { 50% { border-color:#fff ; }  }
.downloadBx{
  background: #afdeb878;
  margin: 4px auto;
  border-radius: 5px;
  text-align: center;
  display: flex;
  padding: 18px;
  gap: 46%;
  line-height: 25px;
  flex-direction: row;
  margin-bottom: 10px;
  -moz-animation: slide 1s ease 3.5s forwards;
  -webkit-animation: slide 1s ease 3.5s forwards;
  -o-animation: slide 1s ease 3.5s forwards;
  -ms-animation: slide 1s ease 3.5s forwards;
   animation: slide 1s ease 3.5s forwards;
  span{
    font-size: 15px;
    line-height: 34px;
    color: #3b5740;
  }
}

.strokeActive{stroke:red;}

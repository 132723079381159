
.askHelp {
  padding: 0;
  .askHelpBtn {
    display: block;
    font-weight: normal;
    font-size: 15px;
    text-transform: uppercase;
    padding: 0px 1rem;
    transition-property: background-color, color;
    transition-timing-function: ease-out;
    transition-duration: 0.25s;
    margin: auto;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    color:white;
    background-color: black;
    border: 1px solid black;
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 30px;
    min-width: 120px;
    justify-content: center;
    b {
      padding: 0.2rem 0.5rem;
      border-radius: var(--border-radius-round);
      background: var(--white);
      color: var(--highlightRed);
      font-weight: var(font-weight-bold);
      border: 2px solid var(--highlightRed);
    }
  }
}
.QuickPopup {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .QuickPopupTitle {
    padding: 1rem;
    border-bottom: 1px solid var(--highlightBlueLight);
    position: relative;
    text-align: center;

    p {
      margin-top: 10px;

      b {
        font-weight: bolder;
        color: black;

      }

     
    }
  }

  .QuickContain {
    display: flex;
    flex-direction: column;

    padding: 0 2rem 1rem;
    gap: 0.5rem;


    p {
      line-height: 20px;
    }
    .askHelpTextArea {
   margin-bottom:10px;
      textarea {
        height: 150px;
      }
   
    }
    .btn {
      max-width: 120px;
      min-width: 120px;

     
    }
    .thankyouPopupImg {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1.4rem 0 1.4rem 0;
    }
    .thankyouContent {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h4 {
        text-align: center;
        line-height: 32px;
        font-weight: bold;
      }
      p {
        line-height: 25px;
        text-align: center;
        max-width: 80%;
        margin: 1rem 0;
      }
    }
  }
}

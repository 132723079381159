.productFeedbackCustomerMsg {
  background: #f9f9f9;
  border-radius: 6px;
  overflow: hidden;
  p {
    padding: 0.75rem;
    margin: 0px;
    &.productFeedbackCustomer {
      display: flex;
      background-color: #eee;
      justify-content: space-between;
    }
  }
}
.productFeedback {
  padding: 0;
  textarea {
    min-height: 250px;
  }
}

.productInfo {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  .productImage {
    background-color: #f4f4f4;
    .img-thumbnail {
      mix-blend-mode: multiply;
      border-width: 1px !important;
      padding: 0;
      background-color: transparent;
      border: none;
      border-radius: 0.25rem;
    }
  }
  p {
    padding: 0px;
    margin: 0px;
    &.productName {
      max-width: 400px;
    }
    &.productSkuLink {
      .productSkuWebLink {
        color: #0980fd;
        text-decoration: underline !important;
      }
    }
  }
  .infoIcon {
    cursor: pointer;
    svg {
      path {
        fill: #0980fd;
      }
    }
  }
  .supplier-info {
    display: none;
    background-color: #f5f6fa;
    padding: 0.3rem 0.5rem;
    flex-direction: column;
    font-size: 12px;
    font-weight: bold;
    border-radius: 4px;
    margin: 0.3rem 0;
    &.active {
      display: flex;
    }
  }
}

.returnRow {
  background: #ffebd057;
  td {
    padding: 0.75rem 0.75rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    border: 1px solid #e9e9ef;
    text-align: center;
    :nth-child(1) {
      text-align: left;
    }
    img {
      border-radius: 4px;
      width: 70px;
      height: 70px;
    }
  }
}

// 
// _nav.scss
// 

.nav-tabs,
.nav-pills {
    >li {
        >a {
            color: #686F7C;
            font-weight: $font-weight-medium;
        }
    }
}

.nav-pills {
    >a {
        color: $gray-700;
        font-weight: $font-weight-medium;
    }
}


.nav-tabs-custom {
    border-bottom: 1px solid $gray-300;

    .nav-item {
        position: relative;
        color: $dark;

        .nav-link {
            border: none;
            background: #fff;

            &.active {
                color: #ffffff;
                background: #080808;
                border:#080808 solid 1px;

                &:after {
                    transform: scale(1);
                }
            }
            &:hover{
                background: #080808;
                border:#080808 solid 1px; color: #fff;
            }

        }
    }

    &.card-header-tabs {
        border-bottom: none;
        
        .nav-link {
            font-weight: $font-weight-medium;
            padding: 3px 8px;
            font-size: 12px;
            border: 1px solid #ccc;
            margin-right: 10px;
            border-radius: 3px;
            background:white;
        }
    }
}


// vertical nav

.vertical-nav {
    .nav {
        .nav-link {
            padding: 24px 16px;
            text-align: left;
            margin-bottom: 8px;

            .nav-icon {
                font-size: 24px;
            }
        }
    }
}
.nav-item{
    text-align: left !important;
}


body[data-layout-mode="dark"] {

    // .nav-tabs
    .nav-link{
        color: $gray-dark-500;
    }

    .nav-tabs{
        border-color: $gray-dark-300;
        .nav-link{
            color: $gray-dark-600;
            &:focus, &:hover{
                border-color:$gray-dark-300 $gray-dark-300 $gray-dark-300;
            }
            &.active{
                background-color: $gray-dark-200;
                border-color:$gray-dark-300 $gray-dark-300 $gray-dark-200;
            }
        }
    }
    
    .nav-pills{
        .nav-link{
            color: $gray-dark-600;
            &.active{
                color: $white;
            }
        }
    }
}